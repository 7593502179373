<template>
  <div class="deviceWorkerStep">
    <div :class="{stepItemLine:item.type == 'line', stepItem:item.type == 'item'}" v-for="(item, index) in stepList">
      <div v-if="item.type == 'item'">
        <div class="itemIcon"
             :style="{'background':nowStep==item.step ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)'}"
        >
          <div class="itemIconMsg"
               :class="{'activeStep':nowStep>=item.step, 'unactiveStep':nowStep<item.step}"
          >
            {{ item.step }}
          </div>
        </div>
        <div class="itemTitle">
          {{ item.itemTitle }}
        </div>
      </div>
      <div class="line" v-if="item.type == 'line'"
           :class="{'activeStep':stepStatus>=item.stepIndex, 'unactiveStep':stepStatus<item.stepIndex}"
      ></div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'deviceWorkerStep',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    dataInfo: {
      handler(value) {
        if (this.$valueIsExist(value, 'status') == false) {
          this.stepStatus = 6
          return
        }
        let status = value['status'];
        if (status == 99) {
          status = 16;
        }
        this.stepStatus = status
      },
      immediate: true,
      deep: true
    },
    stepStatus: {
      handler(value) {
        for (let i = 0; i < this.stepList.length; i++) {
          let data = this.stepList[i]
          if (data.stepIndex != this.stepStatus) {
            continue
          }
          this.nowStep = data.step
          return
        }
        this.nowStep = 1;
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      stepStatus: 6,
      nowStep: 1,
      stepList: [
        { type: 'item', itemTitle: '达到现场', stepIndex: 6, step: 1 },
        { type: 'line', stepIndex: 11 },
        { type: 'item', itemTitle: '开始维修', stepIndex: 15, step: 2 },
        { type: 'line', stepIndex: 12 },
        { type: 'item', itemTitle: '维修完成', stepIndex: 7, step: 3 },
        { type: 'line', stepIndex: 13 },
        { type: 'item', itemTitle: '结算账单', stepIndex: 16, step: 4 }]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerStep {
  width: 90%;
  transform: translate(5.555%, 0px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;
  height: 130px;

  .stepItemLine {
    width: calc(25vw - 60px);
    height: 60px;
    flex-shrink: 0;

    .line {
      height: 2px;
      position: relative;
      top: 16px;
    }
  }

  .activeStep {
    background: white;
  }

  .unactiveStep {
    background: rgba(255, 255, 255, 0.6);
  }

  .stepItem {
    width: 60px;
    flex-shrink: 0;

    .itemIcon {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0px);

      .itemIconMsg {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        text-align: center;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $color_primary;
        font-size: 13px;
      }
    }

    .itemTitle {
      margin-top: 5px;
      width: 100%;
      height: 20px;
      line-height: 20px;
      font-size: 13px;
      color: white;
      text-align: center;
    }


  }


}

</style>