<template>
  <div class="deviceWorkerDealStart">
    <div class="contentCell">
      维修内容
    </div>
    <div class="contentCell">
      <div class="title">故障类型</div>
      <div class="cellMsg_text_right_icon">

        <div class="contentMsg" @click="show=true">
          {{ repairOptionName }}
        </div>
        <svg-icon class="icon" width="16px" height="16px" name="arrow-right"></svg-icon>
      </div>

      <div style="clear: both"></div>
    </div>
    <div class="contentCell">
      <div class="title">维修判断</div>
      <div style="clear: both"></div>
    </div>
    <div class="contentCell">
      <el-input
          v-model="baseData.describe"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入维修处理"
      >
      </el-input>
    </div>


    <van-action-sheet v-model="show" title="故障类型">
      <div class="repairOptions">
        <div class="repairItem"
             :class="{repairActiveItem:item.type==baseData.status}"
             v-for="(item, index) in repairOptions"
             @click="baseData.status=item.type;show=false;"
        >
          {{ item.name }}
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import deviceWorkerRepairMixin from '@components/deviceWorkerRepairOptions/deviceWorkerRepairOptions'

export default {
  name: 'deviceWorkerDealStart',
  mixins: [deviceWorkerRepairMixin],
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    dataInfo: {
      handler(value) {
        let status = 1
        let describe = ''
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    repairOptionName() {
      return this.getStatusTitle(this.baseData.status)
    }
  },
  data() {
    return {
      show: false,
      baseData: {
        status: 1,
        describe: ''
      }
    }
  },
  methods: {
    updateLoading:function(loading) {
      this.$emit('updateLoading', loading)
    },
    updateStatus: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          let post = {
            id: id,
            remark: '',
            repairDetail: this.baseData.describe,
            repairType: this.baseData.status
          }

          this.$api.deviceRepair
              .startRepair(post).then(res => {
            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.updateLoading(true)
      getData().then(res => {
        this.updateLoading(false)
        if (res.result) {
          this.$toast.success('提交成功')
          this.$emit('updateStatus', {})
        } else {
          this.$toast.fail('提交失败')
        }
      })
    }

  }

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerDealStart {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;

  .repairOptions {
    padding: 0px 10px 10px 10px;

    .repairItem {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .repairActiveItem {
      color: $color_primary;
    }


  }

  .contentCell {
    width: 100%;


    .title {
      width: 80px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 13px;
      color: $color3;
    }

    .cellMsg {
      float: right;
      width: calc(100% - 90px);
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellMsg_text_right {
      float: right;
      width: calc(100% - 90px);
      line-height: 30px;
      font-size: 13px;
      text-align: right;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellMsg_text_right_icon {
      float: right;
      width: calc(100% - 90px);

      .contentMsg {
        float: left;
        width: calc(100% - 20px);
        line-height: 30px;
        font-size: 13px;
        text-align: right;
        word-wrap: break-word;
        word-break: break-all;
      }

      .icon {
        float: right;
        width: 16px;
        height: 16px;
        transform: translate(0px, 7px);

      }
    }


  }

}

</style>