<template>
  <div class="deviceWorkerDeal" :style="{ 'background-image': 'url(' + bgBlueImg + ')' }" id="deviceWorkerDeal">
    <device-worker-step :data-info="dataInfo"></device-worker-step>
    <div class="deviceWorkerDealContent">
      <device-worker-report-detail v-if="stepStatus==6||stepStatus==15" :data-info="dataInfo"></device-worker-report-detail>
      <device-worker-deal-arrive @updateLoading="updateLoading" @updateStatus="updateStatus" ref="arrive"
                                 :data-info="dataInfo" v-if="stepStatus==6"
      ></device-worker-deal-arrive>
      <device-worker-deal-start @updateLoading="updateLoading" @updateStatus="updateStatus" ref="start"
                                :data-info="dataInfo" v-if="stepStatus==15"
      ></device-worker-deal-start>
      <device-worker-deal-finished @updateLoading="updateLoading" @updateStatus="updateStatus" ref="finished"
                                   :data-info="dataInfo"
                                   v-if="stepStatus==7"
      ></device-worker-deal-finished>
      <device-worker-deal-bill @updateLoading="updateLoading" @updateStatus="updateStatus" ref="bill"
                               :data-info="dataInfo"
                               v-if="stepStatus==16||stepStatus==99"
      ></device-worker-deal-bill>
    </div>
    <div class="deviceWorkerDealFooter">
      <van-button class="cancelButon" v-if="this.stepStatus==6" @click="cancel">拒绝工单</van-button>
      <van-button class="otherFooterButton" v-if="this.stepStatus==6" @click="footerAction">{{
          footerTitle
        }}
      </van-button>
      <van-button class="footerButton" v-if="this.stepStatus!=6&&this.stepStatus!=99" @click="footerAction">
        {{ footerTitle }}
      </van-button>
    </div>
  </div>
</template>

<script>
import DeviceWorkerStep from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerStep'
import DeviceWorkerDealArrive
  from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerDealArrive'
import DeviceWorkerDealStart
  from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerDealStart'
import DeviceWorkerDealFinished
  from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerDealFinished'
import DeviceWorkerDealBill from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerDealBill'
import DeviceWorkerReportDetail
  from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerReportDetail'

let bgBlueImg = require('../../../../../../static/iconImage/bgimg_blue.png')

export default {
  name: 'deviceWorkerDeal',
  components: {
    DeviceWorkerReportDetail,
    DeviceWorkerDealBill,
    DeviceWorkerDealFinished,
    DeviceWorkerDealStart,
    DeviceWorkerDealArrive,
    DeviceWorkerStep
  },
  computed: {
    nextStatusList() {
      return [
        { status: 5, name: '未处理', ref: '' },
        { status: 6, name: '达到现场', ref: 'arrive' },
        { status: 15, name: '开始维修', ref: 'start' },
        { status: 7, name: '维修完成', ref: 'finished' },
        { status: 16, name: '结算账单', ref: 'bill' },
        { status: 99, name: '结算账单', ref: 'bill' }]
    },
    footerTitle() {
      for (let i = 0; i < this.nextStatusList.length; i++) {
        let data = this.nextStatusList[i]
        if (data.status != this.stepStatus) {
          continue
        }
        return data.name
      }
      return ''
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('deviceWorkerDeal')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingView: null,
      bgBlueImg: bgBlueImg,
      dataInfo: {},
      stepStatus: 6,
      config: {}
    }
  },
  mounted() {
    this.getDataInfo()

    if (this.$valueIsExist(this.$route.query, 'status')) {
      this.config['status'] = this.$route.query['status']
    }
  },
  methods: {
    updateLoading: function(res) {
      this.loading = res
    },
    updateStatus: function() {
      let ref = this.getRef()
      if (ref == 'bill') {
        this.$back()
        return
      }
      delete this.config['status']
      this.getDataInfo()
    },
    getNextStatus: function(status) {
      for (let i = 0; i < this.nextStatusList.length; i++) {
        let data = this.nextStatusList[i]
        if (data.status != status) {
          continue
        }
        if (i + 1 < this.nextStatusList.length) {
          let nextData = this.nextStatusList[i + 1]
          return nextData.status
        }
        return 16
      }
      return 16
    },
    getRef: function() {
      let ref = ''
      for (let i = 0; i < this.nextStatusList.length; i++) {
        let data = this.nextStatusList[i]
        if (data.status != this.stepStatus) {
          continue
        }
        ref = data.ref
      }
      return ref
    },
    cancel: function() {
      let startCancel = () => {
        localStorage.setItem('updateWorkerList', new Date().getTime())
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          this.$api.deviceRepair
              .reportDeny({
                id: id,
                remark: ''
              }).then(res => {

            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.$dialog.confirm({
        message: `是否拒绝该工单`,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            this.loading = true
            startCancel().then(res => {
              this.loading = false
              done()
              if (res.result) {
                this.$toast.success('提交成功')
                localStorage.setItem('updateWorkerList', new Date().getTime())
                this.$back()
              } else {
                this.$toast.fail('提交失败')
              }
            })
          } else {
            done()
          }
        }
      })
    },
    footerAction: function() {
      let ref = this.getRef()
      if (ref == '') {
        console.log('ref = null')
        return
      }
      if (this.$valueIsExist(this.$refs, ref) == false) {
        console.log('ref = null')
        return
      }
      this.$refs[ref].updateStatus()
    },
    getDataInfo: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          this.$api.deviceRepair
              .reportDataInfo({
                id: id,
                photo: '1,2,3,4,5,6'
              }).then(res => {
            if (this.$valueIsExist(res, 'data') == false) {
              resolve({ result: false, data: {} })
            }
            if (this.$valueIsExist(res.data, 'report') == false) {
              resolve({ result: false, data: {} })
            }
            resolve({ result: true, data: res.data['report'] })
          }).catch(e => {
            resolve({ result: false, data: {} })
          })
        })
      }
      this.loading = true
      getData().then(res => {
        this.loading = false
        if (this.$valueIsExist(res.data, 'status') == false) {
          res.data['status'] = 5
        }

        if (this.$valueIsExist(this.config, 'status')) {
          res.data['status'] = this.config['status']
        }

        let status = this.getNextStatus(res.data['status'])

        res.data['status'] = status
        this.dataInfo = res.data
        this.stepStatus = this.dataInfo['status']
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerDeal {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;

  .deviceWorkerDealContent {
    margin-top: 10px;
    height: calc(100% - 160px);
    width: 100%;
    padding: 40px 15px 0px 15px;
  }

  .deviceWorkerDealFooter {
    width: 100%;
    height: 50px;
    bottom: 0px;
    padding: 5px 10px 5px 10px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .cancelButon {
      width: 40%;
      height: 40px;
      border-radius: 20px;
      line-height: 40px;
      overflow: hidden;
      text-align: center;
      background-color: red;
      color: white;
    }

    .otherFooterButton {
      width: 40%;
      height: 40px;
      border-radius: 20px;
      line-height: 40px;
      overflow: hidden;
      text-align: center;
      background-color: $color_primary;
      color: white;
    }

    .footerButton {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      line-height: 40px;
      overflow: hidden;
      text-align: center;
      background-color: $color_primary;
      color: white;
    }

  }

}

</style>