<template>
  <div class="deviceWorkerReportDetail">
    <div class="contentCell">
      <div class="title">故障原因</div>
      <div class="cellMsg_text_right">
        {{ reprotDetail }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentCell">
      <van-grid :border="false" :column-num="4" class="responseDescribeDetailImgList" :gutter="5">
        <van-grid-item v-for="(item, index) in photoList">
          <div style="display: flex;justify-content: center;flex-direction: column;">
            <van-image @click="showImageList(index)" :src="$replacePhoto(item.url)+'&scale=0.2'"/>
          </div>
        </van-grid-item>

      </van-grid>
    </div>

  </div>

</template>

<script>
export default {
  name: 'deviceWorkerReportDetail',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed:{
    photoList() {
      if (this.$valueIsExist(this.dataInfo, 'photos1') == false) {
        return []
      }
      return this.dataInfo['photos1'];
    },
    reprotDetail() {
      if (this.$valueIsExist(this.dataInfo, 'detail') == false) {
        return ''
      }
      return this.dataInfo['detail']
    },
  },
  methods:{
    showImageList: function(index) {
      let list = []
      for (let i = 0; i < this.baseData.photoList.length; i++) {
        let data = this.baseData.photoList[i]
        list.push(this.$replacePhoto(data.url))
      }
      ImagePreview(list, index)
    },
  },

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerReportDetail {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 10px;

  .contentCell {
    width: 100%;

    .title {
      width: 80px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 13px;
      color: $color3;
    }

    .cellMsg {
      float: right;
      width: calc(100% - 90px);
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellMsg_text_right {
      float: right;
      width: calc(100% - 90px);
      line-height: 30px;
      font-size: 13px;
      text-align: right;
      word-wrap: break-word;
      word-break: break-all;
    }

  }

}

</style>