<template>
  <div class="deviceWorkerDealBill">
    <div class="deviceWorkerDealBillContentView">
      <div class="contentCell">
        <div class="cellDetailMsg">订单编号:{{serialNumber}}</div>
        <div class="cellDetailStatus" :class="{outWarranty:!inWarranty, inWarranty:inWarranty}">
          {{ inWarranty ? '在保' : '不在保' }}
        </div>
      </div>

      <div class="contentCell">
        <div class="deviceWorkCellDevice">
          <img class="deviceImg" :src="yonghu">
          <div class="deviceContent">
            <div class="totalCell">
              {{ deviceName }}
            </div>

            <div class="contentCell">
              <div class="deviceContentTitle">设备分类</div>
              <div class="deviceContentcCellMsg">{{ categoryName }}</div>
              <div style="clear: both"></div>
            </div>

            <div class="contentCell">
              <div class="deviceContentTitle">{{$VUEAPPDEVICECODE()}}</div>
              <div class="deviceContentcCellMsg">{{ deviceCode }}</div>
              <div style="clear: both"></div>
            </div>

            <div class="contentCell">
              <div class="deviceContentTitle">报障时间</div>
              <div class="deviceContentcCellMsg">{{ reportTime }}</div>
              <div style="clear: both"></div>
            </div>

          </div>
          <div style="clear: both"></div>
        </div>
      </div>

    </div>

    <div class="deviceWorkerDealBillContentView">
      <div class="contentCell">
        <div class="cellDetailMsg">费用结算</div>
      </div>

      <div class="contentCell">
        <div class="title">更换零件</div>
        <div style="clear: both"></div>
      </div>
      <div class="contentCell">
        <el-input
            :disabled="status==99"
            v-model="baseData.repairComponent"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5}"
            placeholder="请输入更换零件详细"
        >
        </el-input>
      </div>

      <div class="contentCell">
        <van-field :disabled="status==99" v-model="baseData.amount" placeholder="请输入费用"
                   input-align="right" type="number" label="维修总费用"
        />
      </div>
      <div class="contentCell">
        <div class="cellTotalDetailMsg">总价 {{ baseData.amount == '' ? '0' : baseData.amount }}元</div>
      </div>
    </div>
  </div>
</template>

<script>
let yonghu = 'https://img0.baidu.com/it/u=1159755399,1068993229&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500'

export default {
  name: 'deviceWorkerDealBill',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    dataInfo: {
      handler(value) {
        let repairComponent = ''
        if (this.$valueIsExist(value, 'repairComponent')) {
          repairComponent = value['repairComponent']
        }
        this.baseData.repairComponent = repairComponent
        let amount = ''
        if (this.$valueIsExist(value, 'amount')) {
          amount = value['amount']
        }
        this.baseData.amount = amount
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    status() {
      if (this.$valueIsExist(this.dataInfo, 'status') == false) {
        return false
      }
      return this.dataInfo['status']
    },
    inWarranty() {
      if (this.$valueIsExist(this.dataInfo, 'inWarranty') == false) {
        return false
      }
      return this.dataInfo['inWarranty']
    },
    serialNumber() {
      if (this.$valueIsExist(this.dataInfo, 'serialNumber') == false) {
        return ''
      }
      return this.dataInfo['serialNumber']
    },
    reportTime() {
      if (this.$valueIsExist(this.dataInfo, 'reportTime') == false) {
        return ''
      }
      return this.formatDate(this.dataInfo['reportTime'])
    },
    deviceCode() {
      if (this.$valueIsExist(this.dataInfo, 'deviceCode') == false) {
        return ''
      }
      return this.dataInfo['deviceCode']
    },
    categoryName() {
      if (this.$valueIsExist(this.dataInfo, 'categoryName') == false) {
        return ''
      }
      return this.dataInfo['categoryName']
    },
    deviceName() {
      if (this.$valueIsExist(this.dataInfo, 'deviceName') == false) {
        return ''
      }
      return this.dataInfo['deviceName']
    }
  },
  data() {
    return {
      yonghu: yonghu,
      baseData: {
        repairComponent: '',
        amount: ''
      }
    }
  },
  methods: {
    updateLoading:function(loading) {
      this.$emit('updateLoading', loading)
    },
    updateStatus: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }
          let post = {
            amount: this.baseData.amount,
            id: id,
            remark: '',
            repairComponent: this.baseData.repairComponent
          }

          this.$api.deviceRepair
              .repairCheck(post).then(res => {
            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.updateLoading(true)
      getData().then(res => {
        this.updateLoading(false)
        if (res.result) {
          this.$toast.success('提交成功')
          this.$emit('updateStatus', {})
        } else {
          this.$toast.fail('提交失败')
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerDealBill {
  width: 100%;

  .deviceWorkerDealBillContentView {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 10px;

    .contentCell {
      width: 100%;

      .deviceWorkCellDevice {
        width: 100%;
        padding: 5px 10px 5px 10px;


        .deviceImg {
          width: 70px;
          height: 70px;
          border-radius: 8px;
          float: left;
        }

        .deviceContent {
          width: calc(100% - 90px);
          float: right;
          margin-left: 5px;


          .deviceContentTitle {
            width: 80px;
            height: 20px;
            line-height: 20px;
            float: left;
            font-size: 13px;
            color: $color3;
          }

          .deviceContentcCellMsg {
            float: right;
            width: calc(100% - 90px);
            height: 20px;
            line-height: 20px;
            font-size: 13px;
            color: $color3;
            word-wrap: break-word;
            word-break: break-all;
          }

          .totalCell {
            width: 100%;
            height: 20px;
            line-height: 20px;
            font-size: 13px;
          }
        }

      }


      .title {
        width: 80px;
        height: 30px;
        line-height: 30px;
        float: left;
        font-size: 13px;
        color: $color3;
      }

      .cellMsg {
        float: right;
        width: calc(100% - 90px);
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        word-wrap: break-word;
        word-break: break-all;
      }

      .cellDetailMsg {
        float: left;
        width: calc(100% - 60px);
        line-height: 30px;
        font-size: 13px;
        word-wrap: break-word;
        word-break: break-all;
      }

      .inWarranty {
        background-color: $color_primary;
      }

      .outWarranty {
        background-color: red;
      }

      .cellDetailStatus {
        float: right;
        width: 60px;
        line-height: 30px;
        font-size: 13px;
        word-wrap: break-word;
        word-break: break-all;
        color: white;
        text-align: center;
        border-radius: 6px;
        overflow: hidden;
      }

      .cellTotalDetailMsg {
        float: right;
        width: 100%;
        line-height: 30px;
        font-size: 15px;
        font-weight: bold;
        word-wrap: break-word;
        word-break: break-all;
        text-align: right;
      }

      .cellMsg_text_right {
        float: right;
        width: calc(100% - 90px);
        line-height: 30px;
        font-size: 13px;
        text-align: right;
        word-wrap: break-word;
        word-break: break-all;
      }

    }

  }
}

::v-deep .van-cell {
  padding: 10px 10px 10px 0px;
}

</style>