<template>
  <div class="deviceWorkerDealFinished" id="deviceWorkerDealFinished">
    <div class="contentCell">
      维修内容
    </div>
    <div class="contentCell">
      <div class="title">故障类型</div>
      <div class="cellMsg_text_right">
        {{ repairMsg }}
      </div>
      <div style="clear: both"></div>
    </div>
    <div class="contentCell">
      <div class="title">维修判断</div>
      <div style="clear: both"></div>
    </div>
    <div class="contentCell">
      <div class="cellDetailMsg">
        {{ repairDetail }}
      </div>
    </div>
    <div class="contentCell">
      <div class="title">维修描述</div>
      <div style="clear: both"></div>
    </div>
    <div class="contentCell">
      <el-input
          v-model="baseData.describe"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入维修处理"
      >
      </el-input>
    </div>

    <div class="contentCell">
      <div class="cellDetailMsg">维修拍照 <span style="color: #999999;font-weight: normal;font-size: 10px"
      >最多上传3张</span></div>
      <van-grid :border="false" :column-num="4" class="responseDescribeDetailImgList" :gutter="5">
        <van-grid-item v-for="(item, index) in baseData.photoList">
          <div style="display: flex;justify-content: center;flex-direction: column;">
            <van-image @click="showImageList(index)" :src="$replacePhoto(item.url)+'&scale=0.2'"/>
            <van-button style="margin-top: 5px" @click="cancelPhoto(index)" size="mini">取消</van-button>
          </div>
        </van-grid-item>
        <van-grid-item>
          <van-image @click="startPhoto" :src="addImage"/>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

let addImage = require('../../../../../../static/iconImage/addImage.png')
import deviceWorkerRepairMixin from '@components/deviceWorkerRepairOptions/deviceWorkerRepairOptions'

export default {
  name: 'deviceWorkerDealFinished',
  mixins: [deviceWorkerRepairMixin],
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  computed: {
    repairMsg() {
      let repairType = 1
      if (this.$valueIsExist(this.dataInfo, 'repairType')) {
        repairType = this.dataInfo['repairType']
      }
      return this.getStatusTitle(repairType)
    },
    repairDetail() {
      if (this.$valueIsExist(this.dataInfo, 'repairDetail') == false) {
        return ''
      }
      return this.dataInfo['repairDetail']
    }
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {

  },
  mounted() {
    // 配置微信jssdk
    if (typeof localStorage['corpInfo'] != 'undefined') {
      try {
        let corpInfo = JSON.parse(localStorage['corpInfo'])
        this.wxConfig(corpInfo)
      } catch (e) {

      }
    }
  },
  data() {
    return {

      addImage: addImage,
      baseData: {
        describe: '',
        photoList: []
      }
    }
  },
  methods: {
    updateLoading:function(loading) {
      this.$emit('updateLoading', loading)
    },
    cancelPhoto: function(index) {
      this.baseData.photoList.splice(index, 1)
    },
    startPhoto: function() {
      if (this.baseData.photoList.length >= 3) {
        this.$toast.fail('最多上传3张照片')
        return
      }
      this.chooseImage().then(res => {
        console.log('chooseImage')
        console.log(res)
        if (res.result == false) {
          this.$toast.fail('请重新选择图片')
          return
        }
        this.saveImageToServer(res)
      })
    },
    saveImageToServer: function(res) {
      if (res.image == '') {
        this.$toast.fail('图片上传失败')
        return
      }

      let updateImageData = (res) => {
        if (this.$valueIsExist(res, 'id') == false) {
          this.$toast.fail('图片上传失败')
          return
        }
        let info = {
          id: res.id,
          remark: '',
          url: res.url
        }
        this.baseData.photoList.push(info)
      }

      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.updateLoading(true)
          this.uploadImageToWeChatAndGet(res.image, corpInfo).then(res => {
            this.updateLoading(false)
            if (res.result == false) {
              this.$toast.fail('图片上传失败')
              return
            }
            updateImageData(res.image)
          })
        } catch (e) {
          this.$toast.fail('图片上传失败')
        }
      }
    },
    showImageList: function(index) {
      let list = []
      for (let i = 0; i < this.baseData.photoList.length; i++) {
        let data = this.baseData.photoList[i]
        list.push(this.$replacePhoto(data.url))
      }
      ImagePreview(list, index)
    },
    updateStatus: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          let photo = ''
          for (let i = 0; i < this.baseData.photoList.length; i++) {
            let data = this.baseData.photoList[i]
            photo = photo + (photo == '' ? '' : ',') + data.id
          }

          let post = {
            id: id,
            photo: photo,
            remark: '',
            repairResult: this.baseData.describe
          }

          this.$api.deviceRepair
              .repairFinish(post).then(res => {
            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.updateLoading(true)
      getData().then(res => {
        this.updateLoading(false)
        if (res.result) {
          this.$toast.success('提交成功')
          this.$emit('updateStatus', {})
        } else {
          this.$toast.fail('提交失败')
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerDealFinished {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;

  .contentCell {
    width: 100%;


    .title {
      width: 80px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 13px;
      color: $color3;
    }

    .cellMsg {
      float: right;
      width: calc(100% - 90px);
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellDetailMsg {
      float: right;
      width: 100%;
      line-height: 30px;
      font-size: 13px;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellMsg_text_right {
      float: right;
      width: calc(100% - 90px);
      line-height: 30px;
      font-size: 13px;
      text-align: right;
      word-wrap: break-word;
      word-break: break-all;
    }

  }

}

</style>