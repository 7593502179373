<template>
  <div class="deviceWorkerDealArrive">
    <div class="contentCell">
      <div class="title">联系人</div>
      <div class="cellMsg_text_right">
        {{ reportUser }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentCell">
      <div class="title">联系电话</div>
      <div class="cellMsg_text_right">
        {{ phone }}
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="contentCell">
      <div class="title">上门地址</div>
      <div class="cellMsg_text_right">
        {{ address }}
      </div>
      <div style="clear: both"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'deviceWorkerDealArrive',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    phone() {
      if (this.$valueIsExist(this.dataInfo, 'phone') == false) {
        return ''
      }
      return this.dataInfo['phone']
    },
    address() {
      if (this.$valueIsExist(this.dataInfo, 'address') == false) {
        return ''
      }
      return this.dataInfo['address']
    },
    reportUser() {
      if (this.$valueIsExist(this.dataInfo, 'reportUser') == false) {
        return ''
      }
      return this.dataInfo['reportUser']
    }
  },
  methods: {
    updateLoading:function(loading) {
      this.$emit('updateLoading', loading)
    },
    updateStatus: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          let id = ''
          if (this.$valueIsExist(query, 'id')) {
            id = query['id']
          }

          this.$api.deviceRepair
              .reportArrive({
                id: id,
                remark: ''
              }).then(res => {

            resolve({ result: true })
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.updateLoading(true)
      getData().then(res => {
        this.updateLoading(false)
        if (res.result) {
          this.$toast.success('提交成功')
          this.$emit('updateStatus', {})
        } else {
          this.$toast.fail('提交失败')
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceWorkerDealArrive {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;

  .contentCell {
    width: 100%;

    .title {
      width: 80px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 13px;
      color: $color3;
    }

    .cellMsg {
      float: right;
      width: calc(100% - 90px);
      height: 30px;
      line-height: 30px;
      font-size: 13px;
      word-wrap: break-word;
      word-break: break-all;
    }

    .cellMsg_text_right {
      float: right;
      width: calc(100% - 90px);
      line-height: 30px;
      font-size: 13px;
      text-align: right;
      word-wrap: break-word;
      word-break: break-all;
    }

  }

}

</style>